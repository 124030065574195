export const colors = {
  primary: {
    main: "#FFAB00",
  },
  secondary: {
    main: "#3F4E4F",
  },
  info: {
    main: "#A27B5C",
    light: "#baa18c",
  },
  text: {
    main: "#fff",
  },
  icon: {
    main: "#fff",
  },
  logo: {
    main: "#FFAB00",
    light: "#FFF4D5",
  },
  background: {
    main: "#f7f7f7",
    light: "#f7f7f72d",
  },
  tertiary: {
    main: "#DCD7C9",
  },
  chart: {
    main: "#809A6F",
  },
  warning: {
    main: "#DEB6AB",
  },
  blue: "#5B7DB1",
  green: "#18978F",
  pink: "#FBA1A1",
  orange: "#EC994B",
  red: "#ff0000",
  lightGrey: "#ededed",
  success: "#8bbd78",
  grey: "#CFD2CF",
};
