export const MENU_ITEMS_COLLECTION = "menuItems";
export const SERVICES_COLLECTION = "services";
export const OPTIONS = "options";
export const MULTIPLE_OPTIONS = "multiple";
export const UNIQUE_OPTIONS = "unique";
export const COOKIE_MAX_AGE = 10800; //3hours(seconds)
export const CART_EXPIRES_AT = 180; //3hours(minutes)
export const CART_COLLECTION = "cart";
export const ORDERS_COLLECTION = "orders";
export const CONSENT_COOKIE_NAME = "yay-lo-consent";
export const currenctyISO = "PKR";
export const FEEDBACK_COLLECTION = "feedback";
export const MAX_FIRST_LOAD_SECONDS = 8;
