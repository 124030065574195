import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { config } from "./config";

const app = initializeApp(config.firebaseConfig);
if (process.env.NODE_ENV === "development") {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else {
  //@ts-ignore
  if (!window.console) window.console = {};
  console.log = function () {};
}

const db = initializeFirestore(app, {});

// enableMultiTabIndexedDbPersistence(db).catch((err) => {
//   if (err.code === "failed-precondition") {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     console.log("More than one tab opened");
//     typeof window !== "undefined" &&
//       window.alert("Please close other opened menu tabs");
//     // setError(true);
//     // ...
//   } else if (err.code === "unimplemented") {
//     console.log("persistence not available");
//   } else {
//     console.log(err.code);
//     console.log(err);
//   }
// });

const analytics = getAnalytics(app);
const auth = getAuth(app);

export { analytics, auth, db, app };
