import { CardMedia } from "@mui/material";
import { useImage } from "react-image";

export interface ICustomCardImage {
  source: string;
  height: number | string;
  style?: React.CSSProperties;
}
export default function CustomCardImage({
  source,
  height,
  style = {},
}: ICustomCardImage) {
  const { src } = useImage({
    srcList: [source, "/assets/empty_menuitem.svg"],
    useSuspense: true,
  });

  return (
    <CardMedia
      component="img"
      height={height}
      image={src}
      sx={{ width: "auto", margin: "auto" }}
      style={style}
    />
  );
}
