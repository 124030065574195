import React, { lazy, Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import NotHappening from "./components/NotHappening";
import { SnackbarProvider } from "notistack";
import Loading from "./components/loading";

const App = lazy(()=> import("./App"));
const Cart = lazy(() => import("./components/CartPage/CartComponent"));
const Success = lazy(() => import("./components/SuccessPage/Success"));
const Note = lazy(() => import("./components/NotePage/Note"));
const Name = lazy(() => import("./components/NamePage/Name"));
const Profile = lazy(() => import("./components/ProfilePage/Profile"));
const MyOrders = lazy(() => import("./components/MyOrders/MyOrders"));
const MenuItemsComponent = lazy(
  () => import("./components/MenuItemsPage/MenuItemsComponent")
);

// @ts-ignore
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <SnackbarProvider
      autoHideDuration={1500}
      maxSnack={2}
      dense
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<MenuItemsComponent />} />
              <Route path="cart" element={<Outlet />}>
                <Route path="note" element={<Note />} />
                <Route path="name" element={<Name />} />
                <Route index element={<Cart />} />
              </Route>
              <Route path="success" element={<Success />} />
              <Route path="profile" element={<Outlet />}>
                <Route path="myorders" element={<MyOrders />} />
                <Route index element={<Profile />} />
              </Route>
            </Route>
            <Route path="/NotHappening" element={<NotHappening />} />
          </Routes>
        </Suspense>
      </Router>
    </SnackbarProvider>
  </React.StrictMode>
);
reportWebVitals(sendToVercelAnalytics);

