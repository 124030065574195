import { User } from "firebase/auth";
import { createContext } from "react";
import { Cart, emptyCart } from "./types/Cart";
import { MenuItem } from "./types/MenuItem";
import { Service } from "./types/Service";
import {
  ServiceSettings,
  getEmptyServiceSettings,
} from "./types/ServiceSettings";

export interface IAppContext {
  setBottomNavigationIndex: React.Dispatch<React.SetStateAction<number>>;
  setVerticalAlign: React.Dispatch<React.SetStateAction<boolean>>;
  verticalAlign: boolean;
  user: User | null;
  cart: Cart;
  service: Service | null;
  setService: React.Dispatch<React.SetStateAction<Service | null>>;
  cookie: {
    "yay-lo-data"?: any;
  };
  setCookie: (name: "yay-lo-data", value: any, options?: any) => void;
  cartHasPendingWrites: boolean;
  menuItems: MenuItem[];
  selectedSectionName: string;
  setSelectedSectionName: (sectionName: string) => void;
  serviceSettings: ServiceSettings;
  menuItemsLoaded: boolean;
}
export const AppContext = createContext<IAppContext>({
  setBottomNavigationIndex: () => {},
  setVerticalAlign: () => {},
  verticalAlign: false,
  user: null,
  cart: emptyCart,
  service: null,
  setService: () => {},
  cookie: {},
  setCookie: (name: "yay-lo-data", value: any, options) => {},
  cartHasPendingWrites: false,
  menuItems: [],
  selectedSectionName: "",
  setSelectedSectionName: () => {},
  serviceSettings: getEmptyServiceSettings(),
  menuItemsLoaded: false,
});

export default AppContext.Provider;
