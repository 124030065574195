import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

export interface ILoading {
  text?: string;
  value?: number;
}
export default function Loading({ text, value }: ILoading) {
  return (
    <Box>
      {typeof value !== "undefined" && (
        <LinearProgress color={"primary"} variant="determinate" value={value} />
      )}

      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <CircularProgress color={"primary"} thickness={1.5} />

        {text && (
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
