import { Stack, Toolbar, Typography } from "@mui/material";
import { Suspense, useContext, useEffect } from "react";
import { AppContext } from "../AppContextProvider";
import { deleteCart } from "../helpers/deleteCart";
import { colors } from "../theme/colors";
import CustomCardImage from "./CustomCardImage";
import Loading from "./loading";

export default function NotHappening() {
  const { setVerticalAlign, user, cart } = useContext(AppContext);
  useEffect(() => {
    setVerticalAlign(true);

    if (!user) {
      return;
    }
    if (cart.items.length > 0 && cart.price !== 0) {
      console.log("Deleting cart");
      deleteCart(user.uid);
    }

    return () => {
      setVerticalAlign(false);
    };
  }, [cart]);
  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{
        background: colors.background.main,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
      }}
      direction="column"
      spacing={5}
    >
      <Suspense fallback={<Loading />}>
        <CustomCardImage
          height="30vh"
          source={"/assets/scan_qr.svg"}
          style={{ margin: 0, height: "30vh" }}
        />
      </Suspense>
      <Typography variant="h5" align="center">
        Open your camera app and scan a{" "}
        <span style={{ color: colors.primary.main }}>Yay Lo</span> QR code
      </Typography>
      <Toolbar />
    </Stack>
  );
}
