import { FieldValue, Timestamp } from "firebase/firestore";
import { CartItemType } from "./CartItemType";

export type Cart = {
  price: number;
  items: CartItemType[];
  expiresAt?: FieldValue;
  order_id?: string;
  service_id: string;
  table_id: string;
  createdAt?: Timestamp;
  note: string;
  pending?: boolean;
  tax?:number;
  name: string;
};

export const emptyCart: Cart = {
  price: 0,
  items: [],
  order_id: "",
  service_id: "",
  table_id: "",
  note: "",
  name: "",
};
