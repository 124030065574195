import { colors } from "../theme/colors";

export type ServiceSettings = {
  min_order_price?: number;
  min_order_count?: number;
  card_payments?: boolean;
  tipping?: boolean;
  feedback_enabled?: boolean;
  welcome_text?: string;
  unavailable_text?: string;
  closed_text?: string;
  tax_cash?: number;
  tax_card?: number;
  schedule?: { start: ServiceSettingsSchedule; end: ServiceSettingsSchedule }[];
  primary_color: string;
  secondary_color: string;
  tertiary_color: string;
  background_color: string;
};
export type ServiceSettingsSchedule = {
  day: number;
  hours: number;
  minutes: number;
};

export const getEmptyServiceSettings: () => ServiceSettings = () => {
  return {
    min_order_price: 0,
    min_order_count: 0,
    card_payments: true,
    tipping: true,
    feedback_enabled: true,
    welcome_text: "",
    unavailable_text: "",
    closed_text: "",
    background_color: colors.background.main,
    primary_color: colors.primary.main,
    secondary_color: colors.secondary.main,
    tertiary_color: colors.text.main,
  };
};
